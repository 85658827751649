$mat-input-element-color: inherit;
$mat-form-field-label-color: $mat-grey-700;
$mat-form-icon-euro-color: $mat-grey-500;
$mat-form-field-invalid-color: $mat-red-500;
$mat-form-field-label-read-only-color: $mat-grey-700;
$mat-form-field-label-read-only-bg-color: transparent;
$mat-form-field-label-read-total-color: $mat-blue-700;
$mat-form-field-icon-color: $mat-grey-700;
$mat-form-field-info-color: $mat-grey-500;
$mat-form-field-required-marker-color: $mat-red-500;
$mat-form-field-required-marker-bg-color: $white-color;
%mat-form-field-required-marker {
    .mat-form-field-required-marker {
        color: $mat-form-field-required-marker-color;
        float: left;
        background-color: $mat-form-field-required-marker-bg-color;
    }
}

@mixin mat-form-field($theme) {
    .mat-form-field {
        color: $mat-form-field-label-color;
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
        .mat-input-element {
            color: $mat-input-element-color;
            &[type=time] {
                color: $mat-form-field-label-color;
            }
            &::-webkit-calendar-picker-indicator,
            &::-webkit-clear-button,
            &::-webkit-inner-spin-button {
                @include size(un(1.25), un(1.25));
                font-size: 1.5em;
                margin: 0;
                padding: 0;
                opacity: 0.6;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
        .mat-form-field-empty.mat-form-field-label {
            display: inline-flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .mat-form-field-label {
            @extend %mat-form-field-required-marker;
            color: $mat-form-field-label-color;
        }
        &.calculator {
            text-align: right;
        }
        &.read-only {
            text-align: right;
            .mat-form-field-wrapper {
                background-color: $mat-form-field-label-read-only-bg-color;
            }
            .mat-form-field-label {
                color: $mat-form-field-label-read-only-color;
                font-size: 1.1em;
            }
            &.total {
                color: $mat-form-field-label-read-total-color;
                .mat-form-field-label {
                    color: $mat-form-field-label-read-total-color;
                }
            }
        }
        &.mat-focused {
            .mat-select+.mat-form-field-label-wrapper {
                .mat-form-field-label {
                    margin-top: -0.0625em;
                }
            }
            .mat-form-field-label {
                @extend %mat-form-field-required-marker;
            }
        }
        &.mat-form-field-invalid {
            .mat-input-element {
                color: $mat-form-field-invalid-color;
            }
            .mat-form-field-label {
                @extend %mat-form-field-required-marker;
            }
        }
        &.mat-form-field-disabled {
            &.filter-list {
                .mat-form-field-wrapper {
                    margin: 0;
                }
                .mat-form-field-infix {
                    border-top: 0.55em solid transparent;
                }
            }
            .mat-form-field-outline {
                background-color: $mat-grey-100;
            }
        }
        &.description {
            .mat-input-element {
                min-height: un(8);
                line-height: 1.3em;
            }
        }
        &.notes {
            .mat-input-element {
                min-height: un(8);
                line-height: 1.3em;
                font-size: 0.8em;
            }
        }
        .mat-form-field-prefix,
        .mat-form-field-suffix {
            .mat-icon {
                color: $mat-form-field-icon-color;
                &.euro {
                    color: $mat-form-field-icon-color;
                    font-size: un(1.25);
                }
                &.info {
                    color: $mat-form-field-info-color;
                    cursor: help;
                }
            }
            .mat-datepicker-toggle {
                .mat-icon-button {
                    @include size(un(1.25));
                }
            }
        }
        &.select-office {
            .mat-form-field-infix {
                border-top: 0;
                font-size: 16px;
                padding: 2px 0 0 0;
            }
        }
        &.only-error {
            >.mat-form-field-wrapper {
                >.mat-form-field-flex {
                    >.mat-form-field-infix {
                        padding: 0;
                        margin: 0;
                        border-top: 0;
                    }
                }
            }
            .mat-form-field-subscript-wrapper {
                position: relative;
                margin-top: 0;
            }
            &.mat-form-field-invalid {
                .mat-form-field-outline-thick {
                    color: $mat-form-field-label-color;
                }
                .wk-selection-list-content {
                    border-color: $mat-form-field-invalid-color;
                }
            }
        }
        @include sizes($breakpoint: false);
    }
}