@mixin mat-list($theme) {
    .mat-list-base .mat-list-item.task-item .mat-list-item-content,
    .mat-list-base[dense] .mat-list-item.task-item .mat-list-item-content {
        padding: 0 !important;
        justify-content: flex-start !important;
        align-items: flex-start;
        line-height: initial;
        gap: un(.625) !important;
    }
    .mat-menu-content {
        .mat-list {
            padding-top: 0;
            mat-list-item {
                height: un(2.5);
                .mat-list-item-content {
                    .mat-checkbox-layout {
                        .mat-checkbox-label {
                            font-size: un(.875);
                        }
                    }
                }
            }
        }
    }
}