* {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    &:before,
    &:after {
        box-sizing: border-box;
    }
    &:focus {
        outline: none;
    }
}

html {
    font-size: 62.5%;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    line-height: 1.4 !important;
    letter-spacing: -0.1px !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    &.cdk-global-scrollblock {
        position: relative !important;
        overflow: hidden !important;
    }
}

body {
    font-size: 14px;
    &.boxed {
        max-width: 1200px;
        margin: 0 auto;
        @include mat.elevation(8);
    }
    &:not(.is-mobile) {
         ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
            background-color: rgba(0, 0, 0, 0);
        }
         ::-webkit-scrollbar:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }
         ::-webkit-scrollbar-thumb {
            border: 2px solid transparent;
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
            border-radius: 12px;
        }
         ::-webkit-scrollbar-thumb:active {
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
            border-radius: 12px;
        }
    }
}

html,
body {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

details,
menu,
figcaption,
figure,
main,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}

a {
    background-color: transparent;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
    &:not(.mat-button):not(.mat-raised-button):not(.mat-flat-button):not(.mat-stroked-button):not(.mat-icon-button):not(.mat-fab):not(.mat-mini-fab) {
        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}

abbr {
    cursor: help;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
    &[title] {
        border-bottom: none;
        text-decoration: underline dotted;
    }
}

blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.12);
    font-style: italic;
    margin: 1em 0;
    padding-left: 16px;
    footer {
        font-style: normal;
        &:before {
            content: '\2014 \00A0';
        }
    }
    &.reverse {
        border-left: none;
        border-right: 3px solid rgba(0, 0, 0, 0.12);
        text-align: right;
        padding-left: 0;
        padding-right: 16px;
        footer {
            &:before {
                content: '';
            }
            &:after {
                content: '\2014 \00A0';
            }
        }
    }
}

kbd,
pre,
samp,
code {
    font-family: 'Monaco', 'Menlo', 'Consolas', 'Ubuntu Mono', monospace;
    font-size: 1em;
    &:not(.highlight) {
        color: #106CC8;
        padding: 2px 3px;
        border-radius: 2px;
        line-height: 1.7;
    }
}

dfn {
    font-style: italic;
}

dl {
    dt {
        font-weight: bold;
    }
    dd {
        margin: 4px 0 16px 0;
    }
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

mark {
    background-color: #FF0;
    color: #000;
}

.text-italic {
    font-style: italic;
}

.text-semibold {
    font-weight: 400;
}

.text-bold,
strong {
    font-weight: 500;
}

.text-strike {
    text-decoration: line-through;
}

.text-super {
    vertical-align: super;
}

.text-sub {
    vertical-align: sub;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

button,
input[type=email],
input[type=tel],
input[type=text],
input[type=password],
input[type=image],
input[type=submit],
input[type=button],
input[type=search],
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

img {
    border-style: none;
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}

svg:not(:root) {
    overflow: hidden;
}

input {
    border: none;
    padding: 0 16px;
}

button,
input,
optgroup,
select,
textarea {
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
select {
    text-transform: none;
}

button,
input {
    overflow: visible;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

.input-hidden {
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
    width: 0;
}

[hidden] {
    display: none;
}

.mat-button-focus-overlay {
    background: transparent !important;
}

.flex {
    display: flex;
}