.iframe-video {
    display: block;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    .video {
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
    }
}