$dialog-close-size: un(2.5);
@mixin modal-component($theme) {
    @include media-breakpoint('xs') {
        .cdk-overlay-container {
            .cdk-overlay-pane {
                height: 100vh !important;
                width: 100vw !important;
                max-height: 100vh !important;
                max-width: 100vw !important;
                transform: none !important;
                top: 0px !important;
                left: 0px !important;
                right: 0px !important;
                bottom: 0px !important;
                .mat-select-panel.mat-select-panel-filter {
                    min-width: calc(100vw - 64px) !important;
                    max-height: 100vh;
                    max-width: 100vw;
                }
            }
        }
    }
    .cdk-overlay-pane {
        max-height: 100vh;
        max-width: 100vw;
        &.cdk-overlay-certified-modal {
            max-width: 60vw;
            max-height: 80vh;
            width: 60vw;
            height: 80vh;
        }
        &.cdk-overlay-notes-modal,
        &.cdk-overlay-preview-modal {
            height: 80vh !important;
            max-height: 80vh !important;
        }
        &.cdk-overlay-fullscreen {
            .mat-dialog-container {
                background-color: rgba(255, 255, 255, 0.58) !important;
            }
        }
        .mat-tooltip{
            margin: 6px !important;
            max-width: 100% !important;
        }
    }
    .cdk-overlay-backdrop-white {
        background-color: rgba(255, 255, 255, 0.8) !important;
    }
    .cdk-overlay-backdrop-dark {
        background-color: rgba(0, 0, 0, 0.7) !important;
    }
    .cdk-overlay-transparent {
        background: transparent;
        .mat-dialog-container {
            background: transparent;
        }
        .dialog-close {
            color: rgba(255, 255, 255, 0.8);
            background-color: transparent;
        }
    }
    .mat-dialog-container {
        box-shadow: none;
        margin: 0;
        padding: 0;
        max-width: inherit;
        max-height: inherit;
        min-width: inherit;
        min-height: inherit;
        overflow: hidden;
    }
    .dialog-small {
        @include flex(column, $justify-content: space-between, $align-items: center, $align-content: center);
        @include size(100%);
        @include media-breakpoint('lt-sm') {
            @include size(100%);
            max-height: 100vh;
            max-width: 100vw;
        }
        max-height: 60vh;
        max-width: 50vw;
        min-height: un(12);
        min-width: un(18);
    }
    .dialog-report {
        @include flex(column, $justify-content: space-between, $align-items: center, $align-content: center);
        @include size(100%);
        @include media-breakpoint('lt-sm') {
            @include size(100%);
        }
        min-height: un(18);
        min-width: un(20);
    }
    .dialog-header {
        @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
        max-height: un(3.5);
        max-width: 100%;
        min-height: un(3.5);
        min-width: 100%;
        padding: 0;
    }
    .dialog-header-logos {
        @include flex(row, $justify-content: space-around, $align-items: center, $align-content: center, $align-self: center);
        flex-wrap: wrap;
        min-width: calc(100% - $dialog-close-size);
        width: 100%;
    }
    .mat-dialog-title {
        &.dialog-title {
            @include media-breakpoint('lt-sm') {
                margin: 0 0 0 un(1);
                .h2 {
                    font-size: un(1);
                }
            }
            color: $mat-blue-700;
            font-weight: 100;
            margin: 0 0 0 un(1);
        }
        &.title-border {
            border-bottom: $border-grey;
            margin: un(1) un(1) 0 un(1);
            min-width: calc(100% - #{un(2)})
        }
        .h2 {
            color: $mat-blue-700;
            font-weight: 100;
        }
        .h3 {
            color: $mat-grey-600;
            font-weight: 100;
        }
        .error {
            color: $color-error !important;
            font-weight: 500 !important;
        }
    }
    .dialog-close {
        @include size($dialog-close-size);
        flex: 0 0 $dialog-close-size;
        .mat-button {
            min-width: $dialog-close-size;
            padding: 0;
            line-height: $dialog-close-size;
        }
    }
    .mat-dialog-preview,
    .mat-dialog-list {
        @include size(100%);
        overflow: hidden;
    }
    .mat-dialog-body {
        @include size(100%);
        @include media-breakpoint('lt-sm') {
            max-height: calc(100% - #{un(7)});
            min-height: calc(100% - #{un(7)});
        }
        &.preview,
        &.no-actions {
            max-height: calc(100% - #{un(3.5)});
            min-height: calc(100% - #{un(3.5)});
        }
    }
    .mat-dialog-content {
        @include size(100%);
        margin: 0;
        max-width: 100%;
        max-height: calc(100% - #{un(7)});
        min-width: 100%;
        min-height: calc(100% - #{un(7)});
        padding: 0;
        &.single-button {
            @include media-breakpoint('lt-sm') {
                max-height: calc(100% - #{un(7)});
                min-height: calc(100% - #{un(7)});
            }
        }
        &.no-actions {
            max-height: calc(100% - #{un(3.5)});
            min-height: calc(100% - #{un(3.5)});
        }
        &.em {
            padding: 0 un(1);
        }
        &.small {
            max-height: calc(50% - #{un(7)});
            min-height: calc(50% - #{un(7)});
        }
        &.center {
            @include flex(row, $justify-content: center, $align-items: center, $align-content: center);
        }
        &.transparent {
            background-color: transparent !important;
        }
        &.with-list {
            width: 50vw;
            height: 50vh;
            .data {
                font-size: 1.2em;
                padding: 0 un(1);
                .wk-selection-list-message {
                    margin: un(2);
                }
            }
        }
        &.datos {
            padding: un(1) un(2);
        }
        &.jm,
        &.loading,
        &.error {
            .error,
            .loading {
                padding: un(1) un(2);
                font-size: un(1.1);
                text-align: center;
                &-bar {
                    margin-bottom: un(2);
                }
                &-text {
                    margin-bottom: un(1);
                }
            }
            .form {
                padding: 0 un(1);
            }
        }
        &.preview {
            padding: 0 un(1);
        }
        &.confirm {
            .confirm {
                font-size: un(1.25);
                padding: un(.5) un(2) un(2) un(2);
                text-align: center;
                &-text {
                    margin-bottom: un(1);
                }
                &-files {
                    color: $mat-blue-700;
                    font-weight: 100;
                    margin-bottom: un(1);
                }
            }
        }
    }
    .mat-dialog-buttons {
        @include flex(row, $justify-content: space-around, $align-items: center, $align-content: center);
        max-height: un(3.5);
        max-width: inherit;
        min-height: un(3.5);
        min-width: inherit;
    }
    .mat-dialog-actions {
        @include flex(row, $justify-content: space-around, $align-items: center, $align-content: center);
        &.single-button {
            @include flex(row, $justify-content: center, $align-items: center, $align-content: center);
        }
        @include media-breakpoint('lt-sm') {
            gap: un(.5);
        }
        gap: un(1);
        margin: 0;
        max-height: un(3.5);
        max-width: inherit;
        min-height: un(3.5);
        min-width: inherit;
        padding: 0 un(1);
    }
}