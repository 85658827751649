@mixin mat-tab-group($theme) {
    .mat-tab-header-pagination {
        display: none !important;
    }
    .mat-tab-group {
        @include media-breakpoint('lt-md') {
            .mat-tab-labels {
                .mat-tab-label {
                    margin: 0 un(.5);
                    padding: 0;
                }
            }
        }
        &.detail {
            max-height: 100%;
            .mat-tab-header {
                max-height: 32px;
            }
            .mat-tab-label {
                @include media-breakpoint('lt-sm') {
                    font-size: 0.85em;
                    padding: 0;
                }
                height: un(2);
                padding: 0 un(.5);
            }
            .mat-tab-body-content {
                padding: 0 un(1);
            }
        }
        &.vertical {
            @include media-breakpoint('lt-sm') {
                flex-direction: column !important;
            }
            flex-direction: row;
            .mat-tab-header {
                border-bottom: none;
            }
            .mat-tab-labels {
                @include media-breakpoint('lt-sm') {
                    flex-direction: row;
                }
                flex-direction: column;
                .mat-tab-label {
                    @include media-breakpoint('lt-sm') {
                        margin: un(.5);
                        padding: 0 un(1.2);
                    }
                    display: flex;
                    align-items: center;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    user-select: none;
                    height: un(2);
                    border-radius: un(1.2);
                    margin: un(1.5) un(1) 0;
                    &.mat-tab-label-active {
                        background-color: $mat-blue-700;
                        color: $white-color;
                    }
                }
            }
            @include media-breakpoint('gt-sm') {
                .mat-ink-bar {
                    height: 100%;
                    left: 98% !important;
                    background-color: $mat-grey-300;
                    width: 1px;
                }
            }
            .mat-tab-body-wrapper {
                flex: 1 1 auto;
            }
        }
        &.formgroup {
            .mat-ink-bar {
                min-width: un(10.5);
            }
        }
        &.tabs {
            .mat-tab-header {
                border-bottom: none;
            }
            .mat-tab-labels,
            .mat-tab-label {
                font-size: 16px;
                height: un(2);
                padding: 0;
                opacity: 1 !important;
            }
            .mat-tab-link {
                color: $mat-grey-800;
                text-decoration: none;
                opacity: 1 !important;
                &:not(.mat-button):not(.mat-raised-button):not(.mat-flat-button):not(.mat-stroked-button):not(.mat-icon-button):not(.mat-fab):not(.mat-mini-fab) {
                    &:hover,
                    &:active {
                        color: $mat-blue-700;
                        text-decoration: none;
                    }
                }
            }
            &.mat-tab-group.mat-primary .mat-ink-bar {
                background-color: $mat-blue-700;
                height: 5px;
            }
        }
        &.k-gallery-thumbnails {
            height: un(8);
            .mat-tab-header {
                border: none !important;
            }
            .mat-tab-header-pagination {
                box-shadow: none !important;
                display: flex !important;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                align-items: center;
                min-height: un(8);
                .mat-tab-header-pagination-chevron {
                    border-color: $mat-blue-700;
                    border-style: solid;
                    border-width: un(.25) un(.25) 0 0;
                    min-height: un(1.5);
                    min-width: un(1.5);
                    margin: un(1.5) 0;
                }
            }
            .mat-tab-label {
                box-sizing: border-box;
                min-height: un(8);
                min-width: un(8);
                max-width: un(8);
                max-height: un(8);
                opacity: 1;
                padding: un(.5);
            }
            .mat-tab-label-active {
                padding: un(1);
                background-color: rgba(0, 0, 0, 0.04);
            }
            .mat-tab-link {
                &.principal {
                    border-top: $border-grey;
                }
            }
        }
    }
}