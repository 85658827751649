@mixin gioconda-theme($theme) {
    $background: map-get($theme, background);
    $accent: map-get($theme, accent);
    $size-gg-isotipo: un(3);
    $size-wk-isotipo: un(3);
    $size-logo-width: un(9.375);
    $size-logo-height: un(4);
    $size-logo-gg-height: un(6);
    $size-logo-private-width: un(13);
    $size-logo-private-height: un(4);
    .app-main {
        background: map-get($background, default);
        color: map-get($accent, default);
        display: block;
        min-width: 100vw;
        min-height: 100vh;
    }
    a {
        color: map-get($accent, default);
    }
    .accent {
        color: $white-color !important;
        background-color: map-get($accent, default);
    }
    .gg {
        &-isotipo {
            @include media-breakpoint('lt-sm') {
                display: block;
            }
            @include size($size-gg-isotipo);
            display: none;
            background-color: transparent;
            background-image: url($logo-gioconda-isotipo);
            background-attachment: inherit;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            margin-top: 0;
            min-width: $size-gg-isotipo;
            min-height: $size-gg-isotipo;
            max-width: $size-gg-isotipo;
        }
        &-logo {
            @include media-breakpoint('lt-xl') {
                min-height: $size-logo-gg-height;
                min-width: un(18);
                &.private {
                    min-height: $size-logo-private-height;
                    min-width: $size-logo-private-width;
                }
            }
            @include media-breakpoint('lt-lg') {
                min-height: $size-logo-gg-height;
                min-width: un(16);
                &.private {
                    min-width: $size-logo-private-width;
                    min-height: $size-logo-private-height;
                }
            }
            @include media-breakpoint('lt-md') {
                min-height: $size-logo-gg-height;
                min-width: un(14);
                &.private {
                    min-width: $size-logo-private-width;
                    min-height: $size-logo-private-height;
                }
            }
            @include media-breakpoint('lt-sm') {
                display: none
            }
            @include size(100%);
            min-height: $size-logo-gg-height;
            min-width: un(18);
            display: block;
            background-color: transparent;
            background-image: url($logo-gioconda);
            background-attachment: inherit;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 100%;
            &.private {
                max-width: $size-logo-private-width;
                max-height: $size-logo-gg-height;
                min-width: $size-logo-private-width;
                min-height: $size-logo-private-height;
            }
        }
    }
    .wk {
        &-info {
            @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
            @include media-breakpoint('lt-sm') {
                gap: un(.25);
            }
            gap: un(.75);
        }
        &-phone {
            @include media-breakpoint('lt-sm') {
                flex-direction: column;
                margin-right: un(.25);
            }
            @include media-breakpoint('lt-lg') {
                gap: 0;
                font-size: .9em;
            }
            @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
            color: $mat-grey-600;
            gap: un(.25);
            margin-right: un(1.5);
            &-number {
                font-weight: 400;
                font-size: un(1.3125);
                @include media-breakpoint('lt-sm') {
                    font-size: un(1);
                    line-height: un(1.25);
                    text-align: center;
                    white-space: normal;
                }
            }
            &-icon {
                font-size: un(1.5);
                font-weight: 400;
            }
        }
        &-isotipo {
            @include media-breakpoint('lt-md') {
                display: block;
            }
            @include size($size-gg-isotipo);
            background-color: transparent;
            background-image: url($logo-ll-isotipo);
            background-attachment: inherit;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            display: none;
            min-width: $size-wk-isotipo;
            min-height: $size-wk-isotipo;
        }
        &-logo {
            @include media-breakpoint('lt-md') {
                display: none;
            }
            display: block;
            background-color: transparent;
            background-image: url($logo-ll);
            background-attachment: inherit;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 100%;
            min-width: $size-logo-width;
            min-height: $size-logo-height;
            max-width: $size-logo-width;
            max-height: $size-logo-height;
            cursor: pointer;
        }
    }
    .material2 .nav .nav-item .nav-link {
        &.practice {
            margin-top: un(.25);
        }
        &.logo-auctions {
            margin-top: un(1);
            min-height: 55px;
            padding: 0 6px 0 20px;
            background-color: transparent;
            background-image: url($logo-auctions-white);
            background-attachment: inherit;
            background-position: 11px center;
            background-repeat: no-repeat;
            background-size: 200px;
            &:hover,
            &:focus,
            &.accent {
                background-color: $mat-blue-700;
                min-height: 55px;
                padding: 8px 6px 8px 28px;
                border-radius: 0 28px 28px 0;
                margin-right: 6px;
            }
        }
    }
    .material2 .nav .nav-item .nav-button {
        padding: 0;
        min-height: un(2);
        margin-left: 12px;
        margin-top: - #{un(1.5)};
        .mat-button.auction.rejected {
            width: calc(100% - 20px);
        }
    }
}