@mixin message-box($theme) {
    .message-box {
        background-color: $white-color;
        border-color: $mat-grey-500;
        border-radius: 6px;
        border-width: 6px;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-style: solid;
        color: $mat-grey-800;
        padding: 6px;
        &.small {
            @include media-breakpoint('lt-md') {
                max-width: 100vw;
            }
            max-width: 40vw;
            margin: 0 auto;
        }
    }
}