.k-dropdownlist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border: $border-grey;
    padding-left: 8px;
    kendo-resize-sensor {
        display: none;
    }
}

.k-editor .k-editor-content {
    min-height: 300px;
}

.token-highlight {
    color: $mat-blue-700;
}

.ProseMirror .tableWrapper {
    overflow-x: auto;
    margin: 1em 0;
}

.ProseMirror table {
    margin: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
}

.ProseMirror td,
.ProseMirror th {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
}

.ProseMirror th {
    font-weight: bold;
    text-align: left;
}

.k-opinion {
    .k-scrollview-wrap,
    div.k-scrollview {
        margin: 0 auto;
        border: none !important;
        background-color: transparent !important;
    }
    .k-scrollview-wrap .k-scrollview-pageable>li.k-button,
    .k-scrollview-wrap .k-scrollview-nav>li.k-link,
    div.k-scrollview .k-scrollview-pageable>li.k-button,
    div.k-scrollview .k-scrollview-nav>li.k-link {
        margin: 0 un(.5);
        background-color: $mat-grey-400;
    }
    .k-scrollview-wrap .k-scrollview-elements,
    &.k-scrollview .k-scrollview-elements {
        background-color: transparent;
        color: $mat-blue-700;
    }
    .k-scrollview-wrap .k-scrollview-next span,
    .k-scrollview-wrap .k-scrollview-prev span,
    div.k-scrollview .k-scrollview-next span,
    div.k-scrollview .k-scrollview-prev span {
        @include media-breakpoint('lt-md') {
            font-size: 2.5em;
        }
    }
    &.k-scrollview-wrap .k-scrollview,
    &.k-scrollview .k-scrollview-wrap {
        white-space: initial !important;
    }
}

.k-opinion,
.k-gallery {
    .k-scrollview-elements {
        .k-scrollview-next,
        .k-scrollview-prev {
            &:hover {
                text-decoration: none !important;
            }
        }
        .k-scrollview-pageable>.k-button.k-primary,
        .k-scrollview-pageable {
            .k-button {
                &.k-primary {
                    border-color: $mat-blue-700 !important;
                    background-color: $mat-blue-700 !important;
                }
            }
        }
    }
}

.k-gallery-items {
    .k-scrollview-wrap .k-scrollview>li {
        max-height: 50vh !important;
    }
    &.full-screen {
        background-color: transparent !important;
        .principal,
        .k-scrollview-nav-wrap,
        .k-scrollview-wrap {
            background-color: transparent !important;
        }
        .k-scrollview-wrap .k-scrollview>li {
            max-height: 100vh !important;
            max-width: 100vw !important;
        }
    }
    .k-scrollview-next,
    .k-scrollview-prev {
        width: un(2) !important;
        &:hover {
            text-decoration: none !important;
        }
    }
    .k-scrollview-prev {
        left: -#{un(1)};
    }
    .k-scrollview-next {
        right: -#{un(1)};
    }
}