.data {
    text-align: left;
    font-size: 1em;
    line-height: 1.5em;
    &-title {
        font-weight: 400;
        font-size: 1.2em;
    }
    &-list {
        @include unstyle-list;
        &-item {
            padding: un(1) 0;
        }
        &-token {
            display: grid;
            grid-template-columns: 40% 60%;
            grid-column: 2;
            line-height: un(1.2);
            cursor: pointer;
            &:focus,
            &:hover,
            &:active {
                background-color: $data-list-token-hover-bg;
                transition: background-color 200ms ease-in-out;
            }
        }
    }
    &-name {
        font-weight: 300;
        &-proceeding {
            font-weight: 400;
        }
        &-creditor {
            color: $mat-blue-700;
            font-weight: 300;
        }
    }
    &-menu {
        cursor: pointer;
    }
    &-credit,
    &-creditor {
        @include media-breakpoint('lt-sm') {
            display: inline-block;
        }
        color: $mat-blue-700;
        font-size: 1.2em;
        font-weight: 300;
    }
    &-cif {
        color: $mat-grey-500;
        font-size: .9em;
        margin-left: .5em;
    }
    &-label {
        color: $mat-grey-700;
        margin-right: un(.5);
    }
    &-value {
        color: $mat-grey-800;
    }
    &-token {
        color: $mat-blue-700;
        font-size: 0.95em;
        white-space: nowrap;
    }
    &-subject {
        color: $mat-blue-700;
    }
    &-form {
        padding: un(1);
    }
}