@mixin mat-tabs-theme($theme) {
    .mat-tab-nav-bar {
        color: $white-color !important;
        &.landing-tabs {
            background: $nav-background;
            overflow: visible;
            width: 100vw;
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    @include media-breakpoint('lt-md') {
                        font-size: un(1) !important;
                        display: none;
                    }
                    color: $white-color !important;
                    font-size: 18px;
                    font-weight: 100;
                    min-width: un(7.125);
                    opacity: 1;
                    padding: 0;
                    text-decoration: none !important;
                }
            }
            .contact {
                background-color: $mat-red-500;
                color: $white-color;
            }
            .mat-ink-bar {
                background-color: $mat-green-500;
                height: un(.4);
            }
            .button-menu {
                @include media-breakpoint('gt-sm') {
                    display: none;
                }
                @include media-breakpoint('lt-md') {
                    display: block;
                }
            }
        }
    }
}