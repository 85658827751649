@mixin mat-card($theme) {
    $mat-card-color: $mat-blue-600;
    $mat-card-actions-color: $white-color;
    .mat-card {
        &.proceeding {
            border: 1px solid $mat-card-color;
            border-radius: un(0.625);
            .mat-card-actions {
                background-color: $mat-card-color;
                border-bottom-left-radius: un(0.625);
                border-bottom-right-radius: un(0.625);
                color: $mat-card-actions-color;
                padding: 0;
                margin: -#{un(1.0625)};
            }
        }
        &-item {
            font-size: un(1);
            &.relative {
                position: relative;
            }
            .options {
                @include flex(row, $justify-content: flex-start, $align-items: stretch, $align-content: stretch);
                @include media-breakpoint('xs') {
                    @include flex(column, $justify-content: flex-start, $align-items: stretch, $align-content: stretch);
                    margin-bottom: un(1);
                    padding-left: 0;
                }
                &-item {
                    @include media-breakpoint('xs') {
                        min-width: un(17);
                    }
                    min-width: un(12);
                    padding-left: un(.25);
                    &.toggle {
                        padding-left: 0;
                    }
                }
                gap: un(1);
                padding-left: un(.5);
            }
            .only-check {
                margin: 0 0 un(1) 0;
            }
            @include sizes($gap: un(.5));
        }
        &.file-list {
            margin: un(.5) 0;
            padding: un(.5);
            .file {
                &-item {
                    @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
                }
                &-name {
                    flex: 1;
                    margin-right: un(.5);
                }
                &-button {
                    flex: 0;
                }
            }
        }
        &-grid {
            @include media-breakpoint('xs') {
                display: block;
            }
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: un(1);
            width: 100%;
        }
        &-grid-stock {
            @include media-breakpoint('xs') {
                display: block;
                grid-template-columns: auto auto auto;
            }
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: un(.5);
        }
        &-grid-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: un(.5);
        }
        &-grid-msg {
            display: grid;
            grid-template-columns: auto un(1.5);
            grid-template-rows: 1fr;
            grid-column-gap: un(.5);
        }
        &-grid-column {
            display: grid;
            .end {
                justify-self: flex-end;
            }
        }
        &-flex {
            @include flex(row, $justify-content: flex-start, $align-items: center, $align-content: center);
            flex-wrap: wrap;
            column-gap: un(1);
            &.center {
                justify-content: center;
            }
        }
        &-row {
            @include flex(row, $justify-content: flex-start, $align-items: stretch, $align-content: stretch);
            flex-wrap: wrap;
            column-gap: un(1);
        }
        &-button {
            justify-content: flex-end;
            flex: 1;
        }
        &-section {
            line-height: un(1.5);
            margin-bottom: un(.5);
        }
        &-info {
            color: $mat-blue-700;
            margin-top: un(.5);
            padding: un(.5);
        }
        &-label {
            font-weight: 500;
        }
    }
    ul.cadastra {
        @include unstyle-list;
        line-height: un(2);
    }
}