@import 'app_colors_maps';
$mat-grey-50: map-get($grey-palette, 50);
$mat-grey-100: map-get($grey-palette, 100);
$mat-grey-200: map-get($grey-palette, 200);
$mat-grey-300: map-get($grey-palette, 300);
$mat-grey-400: map-get($grey-palette, 400);
$mat-grey-500: map-get($grey-palette, 500);
$mat-grey-600: map-get($grey-palette, 600);
$mat-grey-700: map-get($grey-palette, 700);
$mat-grey-800: map-get($grey-palette, 800);
$mat-grey-900: map-get($grey-palette, 900);
$mat-blue-50: map-get($blue-palette, 50);
$mat-blue-300: map-get($blue-palette, 300);
$mat-blue-400: map-get($blue-palette, 500);
$mat-blue-500: map-get($blue-palette, 500);
$mat-blue-600: map-get($blue-palette, 600);
$mat-blue-700: map-get($blue-palette, 700);
$mat-blue-800: map-get($blue-palette, 800);
$mat-blue-900: map-get($blue-palette, 900);
$mat-blue-A700: map-get($blue-palette, A700);
$mat-red-50: map-get($red-palette, 50);
$mat-red-300: map-get($red-palette, 300);
$mat-red-500: map-get($red-palette, 500);
$mat-red-600: map-get($red-palette, 600);
$mat-red-900: map-get($red-palette, 900);
$mat-green-50: map-get($green-palette, 50);
$mat-green-100: map-get($green-palette, 100);
$mat-green-200: map-get($green-palette, 200);
$mat-green-300: map-get($green-palette, 300);
$mat-green-400: map-get($green-palette, 400);
$mat-green-500: map-get($green-palette, 500);
$mat-green-600: map-get($green-palette, 600);
$mat-green-900: map-get($green-palette, 900);
$mat-purple-500: map-get($purple-palette, 600);
$mat-orange-50: map-get($orange-palette, 50);
$mat-orange-100: map-get($orange-palette, 100);
$mat-orange-200: map-get($orange-palette, 200);
$mat-orange-300: map-get($orange-palette, 300);
$mat-orange-400: map-get($orange-palette, 400);
$mat-orange-500: map-get($orange-palette, 500);
$mat-orange-600: map-get($orange-palette, 600);
$mat-orange-700: map-get($orange-palette, 700);
$mat-orange-800: map-get($orange-palette, 800);
$mat-orange-900: map-get($orange-palette, 900);
///
$white-color: #FFFFFF;
$black-color: #000000;
$true-color: map-get($green-palette, 500);
$false-color: map-get($red-palette, 900);
$liquidated-true-color: map-get($green-palette, 900);
///
$color-green: green !important;
$color-orange: orange !important;
///
$color-error: $mat-red-300;
$color-warning: $color-orange;
$color-success: $color-green;
$color-info: $mat-blue-800;
$color-updated: $mat-purple-500;
$background-color-error: $mat-red-50;
$background-color-success: $mat-green-50;
$background-color-info: $mat-blue-300;
$border-black: 2px solid $black-color;
$border-info: 0.1px solid $mat-blue-700;
$border-navy: 0.1px solid $mat-blue-300;
$border-grey: 0.1px solid $mat-grey-300;
$border-green: 0.1px solid $mat-green-500 !important;
///
$link-badge-default: #b37c05;
$color-auctions: $mat-green-500;
$color-gioconda: $mat-blue-700;
///
$yellow-50: map-get($yellow-palette, 50);
$yellow-100: map-get($yellow-palette, 100);
$yellow-200: map-get($yellow-palette, 200);
$yellow-300: map-get($yellow-palette, 300);
$yellow-400: map-get($yellow-palette, 400);
$yellow-500: map-get($yellow-palette, 500);
$yellow-600: map-get($yellow-palette, 600);
$yellow-700: map-get($yellow-palette, 700);
$yellow-800: map-get($yellow-palette, 800);
$yellow-900: map-get($yellow-palette, 900);
$yellow-A100:map-get($yellow-palette, A100);
$yellow-A200:map-get($yellow-palette, A200);
$yellow-A400:map-get($yellow-palette, A400);
$yellow-A700:map-get($yellow-palette, A700);
///
///
$gioc-info-color: #cba35a;
$gioc-info-text-color: #a0772c;
///
$fabada: 1px solid #fabada;
///
$logo-ll: '/assets/images/logos/laley-logo.svg';
$logo-ll-isotipo: '/assets/images/logos/gioconda-logo-isotipo.svg';
$logo-gioconda: '/assets/images/logos/gioc-logo.svg';
$logo-gioconda-isotipo: '/assets/images/logos/gioc-isotipo.svg';
$logo-auctions: '/assets/images/logos/gioc-auctions-logo.svg';
$logo-auctions-isotipo: '/assets/images/logos/gioc-auctions-isotipo.svg';
$logo-auctions-white: '/assets/images/logos/gioc-auctions-logo-white.svg';
$quotes: '/assets/icons/gio-icons/quotes.svg';
///
$status-ok-bg-color: $color-green;
$status-processing-bg-color: $color-orange;
$status-failed-bg-color: $color-error;
$tr-even-background-color: $mat-grey-100;
$tr-active: map-get($grey-palette, 300);
$tr-active-background-color: rgba($tr-active, .7);
$tr-selected: map-get($yellow-palette, 300);
$tr-selected-background-color: rgba($tr-selected, .25);
$tr-selected-hover-background-color: rgba($tr-selected, .4);
$tr-recognized: map-get($green-palette, 300);
$tr-recognized-selected: rgba($tr-recognized, .3);
$tr-recognized-background-color: map-get($green-palette, 50);
$tr-recognized-hover-background-color: rgba($tr-recognized, .4);
$tr-unrecognized: map-get($red-palette, 300);
$tr-unrecognized-selected: rgba($tr-unrecognized, .3);
$tr-unrecognized-background-color: map-get($red-palette, 50);
$tr-unrecognized-hover-background-color: rgba($tr-unrecognized, .4);
$tr-old-date-background-color: $mat-orange-50;
$tr-old-date-hover-background-color: $mat-orange-100;
$data-list-token-hover-bg: $tr-even-background-color;
///
/// landing
$landing-color: $mat-blue-900;
$nav-background: rgba($mat-blue-900, 1);
$landing-button: rgba($mat-blue-A700, .6);
$landing-button-auction: rgba($mat-blue-700, .7);
$landing-border: 1px solid rgba($white-color, .6);
$color-bankinter: $mat-orange-500;
///