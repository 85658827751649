@mixin print-component($theme) {
    @media print {
        html,
        body {
            height: auto !important;
            overflow: initial !important;
        }
        .app-main {
            .navbar-fuse-sidebar,
            .mat-toolbar,
            .mat-footer,
            .theme-options-button,
            .ps>.ps__rail-x,
            .ps>.ps__rail-y {
                display: none !important;
            }
            #main,
            #container-1,
            #container-2,
            #container-3 {
                padding: 0 !important;
            }
            .ps {
                overflow: visible !important;
            }
            .mat-drawer-container,
            .mat-sidenav-container {
                background-color: white !important;
                .mat-drawer-content,
                .mat-sidenav-content {
                    overflow: initial !important;
                    height: auto !important
                }
            }
        }
        .cdk-overlay-pane {
            &.cdk-overlay-certified-modal {
                @include size(100%);
                margin: 0;
                padding: 0;
                position: absolute;
                left: 0;
                top: 0;
                min-height: 100%;
                min-width: 100%;
                max-width: 100vw;
                max-height: 100vh;
                visibility: visible;
                overflow: visible !important;
                page-break-inside: avoid;
            }
            .mat-dialog-container {
                @include size(100%);
                display: block;
                padding: 0;
                border-radius: 0;
                box-sizing: border-box;
                outline: 0;
            }
            .mat-dialog-content {
                @include size(100%);
                margin: 0;
                padding: 0;
                min-height: 100%;
                min-width: 100%;
                max-width: 100vw;
                max-height: 100vh;
            }
            .dialog-close {
                display: none;
            }
            .mat-dialog-actions {
                visibility: hidden !important;
            }
        }
        .certified {
            @include size(100%);
            border: 0;
            margin: 0;
            padding: 0;
            min-width: 100%;
            visibility: visible;
            overflow: visible !important;
            page-break-inside: avoid;
            &-alert {
                display: none;
                z-index: -1;
            }
        }
    }
}