$mat-radio-outer-circle-color: $mat-green-200;
$accent: $mat-green-400;
$foreground: $grey-palette;
@mixin mat-form-radio-button($theme) {
    .mat-radio-outer-circle {
        border-color: $mat-radio-outer-circle-color;
    }
    .mat-cell-check {
        .mat-radio-button {
            .mat-radio-label-content {
                padding: 0;
            }
        }
    }
    .mat-radio-button {
        &.mat-primary,
        &.mat-accent {
            @include radio-color($accent);
        }
        .mat-radio-label {
            white-space: normal;
        }
        .mat-radio-label-content {
            color: mat.get-color-from-palette($foreground, disabled);
        }
    }
    .radio {
        &-group {
            justify-content: flex-start;
            &-y {
                display: flex;
                flex-direction: column;
            }
            &-x {
                display: flex;
                flex-direction: row;
                margin: 0 0 un(.5) 0;
                flex-wrap: wrap;
                gap: un(.5);
            }
        }
        &-button {
            &-y {
                margin: un(.5);
            }
            &-x {
                margin: un(.25);
                &.size-10 {
                    flex: 0 1 un(10);
                }
            }
        }
    }
    .mat-checkbox {
        &.mark {
            .mat-checkbox-label {
                padding-left: un(.5);
                line-height: un(1.5);
            }
        }
        &.child {
            margin: un(.25);
            font-size: 14px;
        }
        &.mat-checkbox-disabled {
            .mat-checkbox-background {
                background-color: $mat-grey-300;
            }
        }
    }
    .mat-checkbox-frame {
        border: $border-green;
    }
    .mat-checkbox-indeterminate,
    .mat-checkbox-checked {
        &.mat-accent {
            .mat-checkbox-background {
                background-color: $mat-green-500;
            }
        }
        &.mat-checkbox-disabled {
            .mat-checkbox-background {
                background-color: $mat-grey-300;
            }
        }
    }
}

@mixin radio-color($color) {
    &.mat-radio-checked .mat-radio-outer-circle {
        border-color: $color;
    }
    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
        background-color: $color;
    }
}