@mixin cookies($theme) {
    a.qc-cmp2-persistent-link {
        display: none;
        @include media-breakpoint('lt-sm') {
            max-width: 24px;
            max-height: 24px;
            color: $mat-blue-800;
            overflow: hidden;
        }
        background-color: $mat-blue-800;
        color: $mat-blue-50;
        font-weight: 300;
        padding: 5px;
    }
}