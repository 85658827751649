.help {
    font-size: un(1);
    font-weight: 100;
    &-title {
        color: $mat-blue-500;
        border-bottom: .1px solid $mat-blue-500;
    }
    &-text {
        color: $mat-grey-800;
    }
    &-important {
        font-weight: 400;
    }
    &-contact {
        background-color: $mat-red-500;
        color: $white-color;
        display: block;
        font-size: un(1);
        font-weight: 100;
        margin: un(1) 0;
    }
    &-icon {
        @extend %help-icon-size;
        color: $mat-blue-500;
        vertical-align: middle;
        cursor: pointer;
    }
    &-container {
        max-width: un(30);
        max-height: calc(100vh - 250px);
        padding: un(1);
        background-color: $white-color
    }
    &-float {
        float: left;
    }
}

.link {
    cursor: pointer;
}

.m0 {
    margin: 0;
}