@mixin table-component($theme) {
    $icon-header-size: un(1.125);
    $cell-text-color: $mat-grey-800;
    $credit-paid-text-color: $color-green;
    $credit-assets-text-color: $color-orange;
    $credit-debit-text-color: $color-error;
    $status-ok-bg-color: $color-green;
    $status-processing-bg-color: $color-orange;
    $status-failed-bg-color: $color-error;
    $calculator-background-color: rgba(0, 125, 200, 0.05);
    $input-calculator-background-color: transparent;
    $calculator-text-color: $mat-blue-800;
    $input-result-background-color: $white-color;
    $input-result-background-color-hover: $white-color;
    .table-container {
        &.full {
            display: block;
            max-height: un(28);
            overflow: auto;
        }
    }
    .mat-table {
        &.table-group,
        &.table-border,
        &.table-bg-white {
            box-sizing: border-box;
            flex: 1 1 100%;
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            .mat-cell {
                border-bottom: $border-grey;
                border-right: $border-grey;
                &:first-of-type {
                    border-left: $border-grey
                }
                &:last-of-type {
                    border-right: $border-grey
                }
            }
        }
        &.table-bg-color {
            box-sizing: border-box;
            flex: 1 1 100%;
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            .mat-row {
                &:nth-child(even) {
                    background-color: $white-color;
                }
                &.tr-old-date {
                    background-color: $tr-old-date-background-color;
                    &:focus,
                    &:hover,
                    &:active {
                        background-color: $tr-old-date-hover-background-color;
                        transition: background-color 200ms ease-in-out;
                    }
                }
                &.recognized {
                    background-color: $tr-recognized-background-color;
                    &.selected {
                        background-color: $tr-recognized-selected;
                    }
                    &:focus,
                    &:hover,
                    &:active {
                        background-color: $tr-recognized-hover-background-color;
                        transition: background-color 200ms ease-in-out;
                    }
                }
                &.unrecognized {
                    background-color: $tr-unrecognized-background-color;
                    &.selected {
                        background-color: $tr-unrecognized-selected;
                    }
                    &:focus,
                    &:hover,
                    &:active {
                        background-color: $tr-unrecognized-hover-background-color;
                        transition: background-color 200ms ease-in-out;
                    }
                }
            }
        }
        tr.mat-row {
            &:nth-child(even) {
                background-color: $tr-even-background-color;
            }
            &:focus,
            &:hover,
            &:active {
                background-color: $tr-active-background-color;
                transition: background-color 200ms ease-in-out;
            }
            &.selected {
                background-color: $tr-selected-background-color;
                &:focus,
                &:hover,
                &:active {
                    background-color: $tr-selected-hover-background-color;
                    transition: background-color 200ms ease-in-out;
                }
            }
            &.tr-old-task {
                .mat-cell.mat-column-taskDate {
                    color: $false-color;
                }
            }
        }
        &.table-report {
            box-sizing: border-box;
            flex: 1 1 100%;
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            .mat-cell {
                white-space: nowrap;
                border: $border-grey;
                &.error {
                    border-color: $color-error;
                    background-color: $background-color-error;
                    color: $color-error;
                }
                &.updated {
                    border-color: $color-success;
                    background-color: $background-color-success;
                    color: $color-success;
                }
                .mat-icon {
                    &.info {
                        cursor: help;
                    }
                    &+.cell-text {
                        margin-left: un(.5)
                    }
                }
                .cell-text {
                    @include text-overflow;
                    display: inline-block;
                    max-width: un(18);
                    flex: 1;
                    &+.cell-text {
                        display: block;
                    }
                }
                .cell-flex {
                    @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
                    &.cell-float {
                        float: left;
                    }
                    &+.cell-float {
                        float: none;
                    }
                }
            }
        }
        /// Table calculator 
        &.table-calculator {
            width: 100%;
            margin-bottom: un(1);
            .mat-footer-row,
            .mat-header-row {
                border: none;
            }
            .mat-row {
                @include visibleAnimation;
                &:nth-child(even) {
                    background-color: $white-color;
                }
            }
            .mat-cell {
                &:first-of-type {
                    border-left-style: none;
                }
                &:last-of-type {
                    border-right-style: none;
                }
                &.read-only {
                    padding: 0 un(.25);
                    background-color: $calculator-background-color;
                    color: $calculator-text-color;
                    .input-read-only {
                        color: $calculator-text-color;
                        background-color: $input-calculator-background-color;
                        border: none;
                        line-height: un(1.5);
                        max-width: un(8);
                        padding: 0;
                    }
                }
                &.calculator {
                    background-color: $calculator-background-color;
                }
                &.result,
                &.calculator {
                    padding: 0 un(.75);
                    color: $calculator-text-color;
                    .cell-text {
                        color: $cell-text-color;
                    }
                    .input-result,
                    .input-calculator {
                        background-color: $input-result-background-color;
                        border: $border-grey;
                        color: $calculator-text-color;
                        line-height: un(1.75);
                        margin: 0 -#{un(.25)} 0 0;
                        max-width: un(8);
                        padding: 0 un(.25);
                        &.date {
                            max-width: un(10);
                        }
                        &:focus,
                        &:hover,
                        &:active {
                            background-color: $input-result-background-color-hover;
                        }
                    }
                }
                &.date {
                    max-width: un(8);
                    padding: 0 un(.25);
                }
            }
            .mat-footer-cell,
            .mat-header-cell {
                &.calculator {
                    background-color: $calculator-background-color;
                    color: $calculator-text-color;
                }
                &.result {
                    padding: 0 un(.75);
                    color: $calculator-text-color;
                }
            }
            .mat-datepicker-toggle {
                width: un(3);
                color: $mat-grey-500;
                .mat-icon-button {
                    @include size(un(1.5));
                    line-height: un(1.5);
                    top: 0;
                }
            }
        }
        &.table-border {
            .mat-footer-cell,
            .mat-header-cell {
                background-color: $white-color !important;
                border-right-style: none;
                border-left-style: none;
                color: $mat-grey-900;
                font-weight: 500;
            }
            .mat-cell {
                border-right-style: none;
                border-left-style: none;
                &.warning {
                    color: $color-warning;
                }
            }
        }
        &.table-group {
            .mat-header-row {
                &.header-group {
                    padding: 0;
                    .mat-header-cell {
                        border: un(.5) solid $white-color;
                        text-align: center;
                        vertical-align: middle;
                        &.cdk-column-01 {
                            background-color: $mat-grey-300;
                            border-left: none;
                            border-right: un(.25) solid $white-color
                        }
                        &.cdk-column-02 {
                            background-color: $mat-blue-300;
                            border-left: un(.25) solid $white-color;
                            border-right: un(.25) solid $white-color;
                        }
                        &.cdk-column-03 {
                            background-color: $mat-orange-50;
                            border-left: un(.25) solid $white-color;
                            border-right: none;
                        }
                    }
                }
            }
            .mat-header-cell,
            .mat-cell {
                &.mat-column-01 {
                    border-right: un(.25) solid $white-color;
                }
                &.mat-column-02-first {
                    border-left: un(.25) solid $white-color;
                }
                &.mat-column-02-last {
                    border-right: un(.25) solid $white-color;
                }
                &.mat-column-03-first {
                    border-left: un(.25) solid $white-color;
                }
                &-icon {
                    padding: 0 !important;
                }
            }
        }
        [class*=wk-icon-] {
            color: inherit;
        }
    }
    .mat-cell:last-of-type,
    .mat-footer-cell:last-of-type,
    .mat-header-cell:last-of-type,
    .mat-cell:first-of-type,
    .mat-footer-cell:first-of-type,
    .mat-header-cell:first-of-type {
        padding: 0 un(.25);
    }
    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
        @include visibleAnimation;
        font-size: 14px;
        height: un(2.5);
        padding: 0 un(.25);
        &.text-right {
            text-align: right;
            .mat-sort-header-container {
                justify-content: flex-end;
            }
        }
        &-check {
            @include size(un(2.5));
            min-width: un(2.5);
            text-align: center;
        }
        &-icon {
            @include size(un(2.5));
            min-width: un(2.5);
            padding: 0 !important;
            text-align: center;
            .mat-sort-header-container {
                .mat-icon-button,
                .mat-icon {
                    font-size: $icon-header-size;
                    width: $icon-header-size;
                    height: $icon-header-size;
                    min-width: $icon-header-size;
                    min-height: $icon-header-size;
                    line-height: $icon-header-size;
                }
            }
            &.td.mat-column-isExcluded,
            &.td.isExcluded {
                color: $mat-red-300 !important;
            }
        }
        &.filter {
            max-width: un(4);
            text-align: center;
        }
        &-toggle {
            min-width: un(5.3);
            text-align: center;
        }
        .filter {
            @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
            &-help {
                @include flex(row, $justify-content: center, $align-items: stretch, $align-content: stretch);
            }
            .selected {
                color: $mat-red-300 !important;
            }
        }
        &-help {
            @include flex(row, $justify-content: flex-end, $align-items: center, $align-content: center, $align-self: flex-end);
            gap: un(.5);
        }
        &-mark {
            @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
            .cell-help {
                flex: 0 1 un(1.5);
            }
            .cell-mark {
                flex: 0 1 un(2.5);
            }
            .cell-text {
                flex: 1;
            }
        }
        .photo {
            cursor: pointer;
            max-width: un(2.5);
            max-height: un(2.5);
        }
    }
    .mat-footer-cell {
        @include visibleAnimation;
    }
    .mat-cell {
        @include visibleAnimation;
        a {
            cursor: pointer;
        }
        &-notes {
            @include flex(row, $justify-content: space-between, $align-items: center, $align-content: center);
            gap: un(.5);
            flex-grow: 1;
        }
    }
    .mat-header-row {
        height: un(2.5);
    }
    .mat-header-color {
        background-color: $mat-grey-200;
    }
    .mat-row {
        height: inherit;
    }
    .paid {
        color: $credit-paid-text-color;
    }
    .assets {
        color: $credit-assets-text-color;
    }
    .debit {
        color: $credit-debit-text-color;
    }
    .link-badge {
        background-color: $link-badge-default;
        border-radius: un(.625);
        color: $white-color;
        display: inline-block;
        max-width: max-content;
        padding: 0 un(.5);
        &.true {
            background-color: $true-color;
        }
        &.false {
            background-color: $false-color;
        }
        &.cero {
            background-color: transparent
        }
        &.info {
            color: $color-info;
            border: $border-info;
            background-color: transparent
        }
        &.super {
            @include size(un(.5));
            border-radius: un(.5);
            display: inline-block;
            padding: 0;
        }
    }
    .select-list-status {
        border: $border-grey;
        margin: 0;
        min-width: 100%;
        min-height: un(2);
        padding: 0 un(.25);
    }
    .input-list-credits {
        border: $border-green;
        margin: 0;
        min-width: un(8);
        min-height: un(2);
        padding: 0 un(.25);
        text-align: right;
    }
    .select-list-credits {
        border: $border-green;
        margin: 0;
        min-width: un(11);
        min-height: un(2);
        padding: 0 un(.25);
    }
    .status {
        display: inline-block;
        width: 14px;
        height: 14px;
        background-color: $mat-grey-700;
        border-radius: 14px;
        margin-bottom: -3px;
        &.ok {
            background-color: $status-ok-bg-color;
        }
        &.processing {
            background-color: $status-processing-bg-color;
        }
        &.failed {
            background-color: $status-failed-bg-color;
        }
        &.true {
            background-color: $true-color;
        }
        &.false {
            background-color: $false-color;
        }
    }
    .status-badge {
        display: inline-block;
        padding: 0 un(.5);
        border-radius: un(.625);
        color: $white-color;
        font-size: .9em;
        &.true {
            background-color: $mat-green-300;
        }
        &.false {
            background-color: $mat-red-300;
        }
    }
    .totalStock {
        color: $false-color;
        &.true {
            color: $true-color;
        }
    }
    .liquidated {
        &.true {
            color: $liquidated-true-color !important;
        }
        &.false {
            color: $false-color !important;
        }
    }
    .awarded,
    .auctionStatus,
    .activated,
    .auctionSituation {
        &.true {
            color: $true-color !important;
        }
        &.false {
            color: $false-color !important;
        }
    }
    .hidden {
        display: none;
    }
    .mat-slide-toggle {
        &.background {
            background-color: $white-color;
            padding: un(.5) un(.5) 0 un(.5);
        }
        &.slide-head {
            height: initial;
            .mat-icon {
                display: none;
            }
            &.row {
                .mat-slide-toggle-label {
                    flex-direction: row;
                }
            }
            .mat-slide-toggle-label {
                @include media-breakpoint('lt-md') {
                    flex-direction: row;
                }
                flex-direction: column;
                text-align: center;
            }
            &.off {
                opacity: .38;
                .mat-slide-toggle-bar {
                    background-color: $mat-grey-100;
                    .mat-slide-toggle-thumb-container {
                        left: 8px;
                    }
                }
                &.mat-checked {
                    .mat-slide-toggle-thumb-container {
                        left: -11px;
                    }
                }
            }
        }
        &.mat-checked {
            .mat-slide-toggle-thumb-container {
                transform: translate3d(20px, 0, 0);
            }
            .mat-slide-toggle-thumb {
                background: rgba(255, 255, 255, 1);
                background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(227, 227, 227, 1) 100%);
            }
            .mat-slide-toggle-bar {
                background-color: #a7d773;
            }
        }
        .mat-slide-toggle-bar {
            width: 36px;
            height: 16px;
            border: 1px solid #a8a8a8;
            background-color: #ff9fa0;
        }
        .mat-slide-toggle-thumb-container {
            width: 16px;
            height: 16px;
            top: -1px;
            left: -1px;
            .mat-slide-toggle-thumb {
                height: 16px;
                width: 16px;
                border: 1px solid #a8a8a8;
                box-shadow: none;
                background: rgba(255, 255, 255, 1);
                background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(227, 227, 227, 1) 100%);
            }
        }
    }
    .mat-column-star {
        width: 20px;
        padding-right: 8px;
    }
    .mat-column-position {
        padding-left: 8px;
    }
    /// size column type date
    .mat-column {
        &-sentDate {
            max-width: un(10);
            min-width: un(7.5);
        }
        &-judicialDate,
        &-creationDate {
            max-width: un(10);
        }
    }
    .extension {
        @include size(un(2.5));
        border: $border-info;
        border-radius: 100%;
        background: $mat-grey-100;
        color: $mat-blue-700;
        display: inline-block;
        font-size: un(.75);
        font-weight: 500;
        line-height: un(2.5);
        text-align: center;
        text-transform: uppercase;
    }
}