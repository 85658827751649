/// Sets the measure in relation of the base unit
/// @param {Number} $value - value to sets the measure
/// @example scss
/// p { padding: 0 0 un(2) un(1.5);}
/// @requires {Variable} $base-unit
/// @returns {Number}
///
///
$base-unit: 16px;
@function un($value) {
    @return $base-unit * $value;
}

$breakpoints: ( xs: 'screen and (max-width: 599px)', sm: 'screen and (min-width: 600px) and (max-width: 959px)', md: 'screen and (min-width: 960px) and (max-width: 1279px)', lg: 'screen and (min-width: 1280px) and (max-width: 1919px)', xl: 'screen and (min-width: 1920px) and (max-width: 5000px)', lt-sm: 'screen and (max-width: 599px)', lt-md: 'screen and (max-width: 959px)', lt-lg: 'screen and (max-width: 1279px)', lt-xl: 'screen and (max-width: 1919px)', gt-xs: 'screen and (min-width: 600px)', gt-sm: 'screen and (min-width: 960px)', gt-md: 'screen and (min-width: 1280px)', gt-lg: 'screen and (min-width: 1920px)') !default;
$helper-breakpoints: ( xs: null, sm: 'gt-xs', md: 'gt-sm', lg: 'gt-md', xl: 'gt-lg');
@mixin media-breakpoint($breakpointName) {
    $mediaQuery: map-get($breakpoints, $breakpointName);
    @if ($mediaQuery==null) {
        @content
    }
    @else {
        @media #{$mediaQuery} {
            @content
        }
    }
}

/// flex row
/// @param {String} $direction - row: row | row-reverse | column | column-reverse
/// @param {String} $justify-content - space-between: flex-start | flex-end | center | space-between | space-around
/// @param {String} $align-content - stretch: flex-start | flex-end | center | space-between | space-around | stretch
/// @param {String} $align-items - center: flex-start | flex-end | center | stretch | baseline
/// @param {String} $align-self - center: flex-start | flex-end | center | stretch | baseline | auto
///  display: flex;
///  flex-direction: row;
///  flex-wrap: nowrap;
///  justify-content: flex-start;
///  align-items: stretch;
///  align-content: stretch;
@mixin flex ($direction: false, $justify-content: space-between, $align-content: stretch, $align-items: center, $align-self: center) {
    display: flex;
    justify-content: $justify-content;
    align-content: $align-content;
    align-items: $align-items;
    align-self: $align-self;
    @if ($direction) {
        flex-direction: $direction;
    }
}

// Requires inline-block or block for proper styling
@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/// Set width and heihgt
/// @param {Number} $width - Width for the item
/// @param {Number} $height [$width] - Height for the item
/// @example scss - Usage
///   @include size(16px, 20px);
///   @include size($unit);
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

/// @group prefix
/// @type prefix
///
$prefixes: 'webkit',
'moz',
'ms',
'o',
'none';
/// Mixin that includes prefixex for diferent browsers
/// @access public
/// @param {String} $property
/// @param {String} $arguments
/// @example scss - Usage
///       @include prefix(column-count, 2);
@mixin prefix($property, $arguments) {
    @each $prefix in $prefixes {
        @if $prefix=='none' {
            #{$property}: $arguments;
        }
        @else {
            -#{$prefix}-#{$property}: $arguments;
        }
    }
}

@mixin modifier($modifier) {
    &#{$modifier} {
        @content;
    }
}

///
/// @extend 
///
$help-icon-size: un(1.25) !important;
%help-icon-size {
    font-size: $help-icon-size;
    width: $help-icon-size;
    height: $help-icon-size;
    min-width: $help-icon-size;
    min-height: $help-icon-size;
    line-height: $help-icon-size;
}

%full-block-relative {
    @include size(100%);
    box-sizing: border-box;
    display: block;
    position: relative;
}

%full-block {
    @include size(100%);
    box-sizing: border-box;
    display: block;
}

%block-width {
    width: 100%;
    box-sizing: border-box;
    display: block;
}

%block-relative {
    width: 100%;
    box-sizing: border-box;
    display: block;
    position: relative;
}

@mixin unstyle-list($child-blocks: false) {
    list-style: none;
    margin: 0;
    padding: 0;
    @if $child-blocks {
        >li {
            display: block;
            >a {
                display: block;
            }
        }
    }
}

@mixin sizes($gap: false, $breakpoint: true) {
    @for $i from 1 through 20 {
        $size: $i * 5;
        $width: $size*1%;
        &.size-#{$size} {
            @include media-breakpoint('lt-sm') {
                min-width: 100%;
            }
            @if $breakpoint {
                @include media-breakpoint('gt-md') {
                    $bigWidth: $size*0.80%;
                    @if $gap {
                        min-width: calc(#{$bigWidth} - #{$gap});
                        max-width: calc(#{$bigWidth} - #{$gap});
                    }
                    @else {
                        min-width: #{$bigWidth};
                        max-width: #{$bigWidth};
                    }
                }
            }
            @if $gap {
                min-width: calc(#{$width} - #{$gap});
                max-width: calc(#{$width} - #{$gap});
            }
            @else {
                min-width: #{$width};
                max-width: #{$width};
            }
        }
    }
}

$visible-animation-start-background-color: $mat-blue-400;
$visible-animation-end-background-color: $white-color;
$visible-animation-middle-background-color: $mat-blue-50;
@mixin visibleAnimation ($color-start: $visible-animation-start-background-color, $color-middle: $visible-animation-middle-background-color, $color-end: $visible-animation-end-background-color) {
    &.visibleAnimation {
        @include prefix(animation-name, cambiarColor);
        @include prefix(animation-duration, 1s);
        @include prefix(animation-iteration-count, 1);
    }
    @-webkit-keyframes cambiarColor {
        0% {
            background-color: $color-start;
        }
        50% {
            background-color: $color-middle;
        }
        100% {
            background-color: $color-end;
        }
    }
    @keyframes cambiarColor {
        0% {
            background-color: $color-start;
        }
        50% {
            background-color: $color-middle;
        }
        100% {
            background-color: $color-end;
        }
    }
}