@mixin table-mobile-component($theme) {
    @include media-breakpoint('lt-md') {
        .mat-table {
            &.ml {
                border: none !important;
                .mat-header-row {
                    height: auto !important;
                    border: none !important;
                    &.header-group {
                        margin: 0;
                        border: none !important;
                    }
                    .mat-header-cell {
                        &.mat-column-taskDate {
                            display: table-cell !important;
                            border: none !important;
                            .filter {
                                max-width: un(6);
                            }
                        }
                        display: none;
                    }
                }
                .mat-footer-row,
                .mat-row {
                    @include flex(row, $justify-content: flex-start, $align-items: flex-start, $align-content: center);
                    border: $border-grey;
                    flex-wrap: wrap;
                    min-height: auto;
                    padding: un(.5);
                    .mat-footer-cell,
                    .mat-cell {
                        @include flex(row, $justify-content: space-between, $align-content: center, $align-items: flex-start, $align-self: center);
                        border: none;
                        height: auto;
                        min-width: 100%;
                        padding: 0;
                        word-break: break-word;
                        gap: un(.3);
                        &:before {
                            content: attr(aria-label);
                            font-weight: 300;
                            text-align: left;
                            word-break: normal;
                        }
                        .cell-link {
                            display: block;
                            width: 100%;
                            font-size: 1.2em;
                        }
                        &.empty {
                            display: none;
                        }
                        &.mat-column {
                            &-preview,
                            &-newfile,
                            &-viewDownload,
                            &-name,
                            &-extension,
                            &-filesAttached,
                            &-documentNumber,
                            &-delete,
                            &-recognized,
                            &-creditorReported,
                            &-emailStatus,
                            &-templateType,
                            &-editButton,
                            &-assetsLiquidationRequestAssetsCount,
                            &-calendarTaskType,
                            &-isExcluded,
                            &-selectCheckBox {
                                &:before {
                                    content: none;
                                }
                            }
                            &-preview,
                            &-viewDownload,
                            &-newfile,
                            &-extension,
                            &-filesAttached,
                            &-delete,
                            &-recognized,
                            &-creditorReported,
                            &-emailStatus,
                            &-templateType,
                            &-editButton,
                            &-calendarTaskType,
                            &-isExcluded,
                            &-selectCheckBox {
                                @include size(un(2.5));
                                min-width: un(2.5) !important;
                            }
                        }
                        &.mat-cell-check-4+.mat-cell {
                            min-width: calc(100% - #{un(10)}) !important;
                            max-width: calc(100% - #{un(10)}) !important;
                            justify-content: flex-start;
                        }
                        &.mat-cell-check-3+.mat-cell {
                            min-width: calc(100% - #{un(7.5)}) !important;
                            max-width: calc(100% - #{un(7.5)}) !important;
                            justify-content: flex-start;
                            &.cell-text {
                                padding-left: un(.5);
                            }
                        }
                        &.mat-cell-check-2+.mat-cell {
                            min-width: calc(100% - #{un(5)}) !important;
                            max-width: calc(100% - #{un(5)}) !important;
                            justify-content: flex-start;
                        }
                        &.mat-cell-check+.mat-cell {
                            min-width: calc(100% - #{un(2.5)}) !important;
                            max-width: calc(100% - #{un(2.5)}) !important;
                            justify-content: flex-start;
                        }
                        &.mat-column-principalImage {
                            justify-content: center;
                            .photo {
                                max-width: 50vw;
                                max-height: 50vh;
                            }
                        }
                    }
                }
                .mat-checkbox {
                    @include size(un(1.5), un(2.5));
                    @include flex(column, $justify-content: center, $align-items: center, $align-content: center);
                }
            }
        }
    }
}