@mixin mat-accordion($theme) {
    .mat-accordion {
        .mat-expansion-panel:not([class*='mat-elevation-z']),
        .mat-expansion-panel {
            @include prefix(box-shadow, none);
            &:first-of-type,
            &:last-of-type {
                border-radius: 0 !important;
            }
            &.info {
                .mat-expansion-panel {
                    &-body {
                        border: $border-info;
                        padding: 0;
                    }
                }
            }
            &.card-form {
                border: 0;
                margin: 0 0 un(1) 0;
                &:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
                    background-color: $mat-grey-200;
                }
                .mat-expansion-panel {
                    &-header {
                        @include media-breakpoint('xs') {
                            max-height: 100% !important;
                            height: 100% !important;
                            min-height: un(2.5);
                            padding: un(1);
                        }
                        background-color: $mat-grey-200;
                        max-height: un(3) !important;
                        &:hover,
                        &:focus {
                            background-color: $mat-grey-200;
                        }
                        &-title {
                            color: $mat-blue-800;
                            font-size: 18px;
                            font-weight: 100;
                        }
                    }
                    &-body {
                        border: $border-grey;
                        padding: un(1);
                    }
                }
                &.duplicated {
                    .mat-expansion-panel {
                        &-body {
                            background-color: $mat-grey-50;
                        }
                    }
                }
                .mat-card-group {
                    .h4 {
                        font-size: 1.2em;
                        font-weight: 500;
                        margin: un(2) 0 0 0;
                        &.new {
                            color: $mat-green-500;
                        }
                        &.updated {
                            color: $mat-purple-500;
                        }
                        &.error {
                            color: $mat-red-500;
                        }
                    }
                }
                .mat-card-item {
                    @include media-breakpoint('lt-md') {
                        flex-wrap: wrap;
                    }
                    &.mat-card-table {
                        min-width: un(30);
                        overflow: auto;
                    }
                }
                .mat-expansion-panel-spacing:last-child,
                &:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
                    margin-bottom: un(2);
                }
            }
            &.card-list {
                border: none !important;
                box-shadow: none !important;
                margin: un(.5) 0 !important;
                &:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
                    background-color: $white-color;
                }
                .mat-expansion-panel {
                    &-header {
                        background-color: $white-color;
                        max-height: un(2.5) !important;
                        padding: 0 un(.5) !important;
                        border-bottom: $border-grey;
                        &:hover,
                        &:focus {
                            background-color: $mat-grey-50;
                        }
                        &-title {
                            color: $mat-blue-700;
                            font-size: un(1);
                            font-weight: 100;
                        }
                    }
                    &-content {
                        border-bottom: $border-grey;
                    }
                    &-body {
                        padding: un(.5);
                    }
                }
            }
            &.card-tips {
                box-shadow: none !important;
                margin: un(.5) 0 !important;
                &:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
                    background-color: $background-color-info;
                }
                .mat-expansion-panel {
                    &-header {
                        background-color: $white-color;
                        max-height: un(2.5) !important;
                        padding: 0 un(.5) !important;
                        border-bottom: $border-info;
                        border-top: $border-info;
                        &.mat-expanded,
                        &:hover,
                        &:focus {
                            background-color: $background-color-info;
                        }
                        &-title {
                            color: $mat-grey-800;
                            font-size: un(1);
                            font-weight: 100;
                        }
                    }
                    &-content {
                        border-bottom: $border-grey;
                    }
                    &-body {
                        font-size: un(1);
                        padding: un(.5) un(1.5);
                    }
                }
            }
        }
    }
    .gg-accordion-group {
        &.row {
            @include flex(row, $justify-content: space-between, $align-items: stretch, $align-content: stretch);
            @include media-breakpoint('lt-md') {
                flex-direction: column;
                gap: 0;
            }
            gap: un(1);
            width: 100%;
            flex-wrap: wrap;
        }
        &.column {
            @include flex(column, $justify-content: space-between, $align-items: stretch, $align-content: stretch);
        }
        &-panel {
            @include media-breakpoint('lt-md') {
                width: 100%;
            }
            width: calc(50% - #{un(.5)});
            @include sizes($gap: 8px);
        }
    }
}