@mixin mat-menu($theme) {
    .mat-list-base {
        margin-bottom: un(.5);
        &:last-child {
            margin-bottom: 0;
        }
        .mat-subheader {
            border-bottom: $border-info;
            color: $mat-blue-700;
            font-weight: 400;
            max-height: un(2);
        }
        &.tips {
            padding: un(1);
            .mat-pseudo-checkbox {
                display: none;
            }
            .mat-list-option {
                color: $mat-grey-800;
                height: un(2);
                line-height: un(2);
                padding: 0;
            }
            .mat-list-text {
                color: $mat-grey-800;
                flex-direction: row;
            }
            [class*=wk-icon] {
                color: $mat-grey-600;
                display: inline-block;
                font-size: un(1.25);
                line-height: un(1.75);
                margin-left: 0;
                margin-right: 0;
                width: un(3.5);
            }
        }
        &.helps {
            padding: un(2);
            .mat-list-option {
                color: $mat-grey-800;
            }
            .mat-list-text {
                color: $mat-grey-800;
                flex-direction: row;
                align-content: center;
                align-items: center;
            }
        }
    }
    .mat-select-panel .mat-option {
        max-height: un(2);
    }
    .mat-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
    }
    .mat-select-panel-filter {
        .mat-option {
            color: $mat-grey-800;
            max-height: 3em;
        }
        .mat-option-text {
            max-height: 3em;
            line-height: 3em;
        }
    }
    .mat-menu-panel {
        &.rueda {
            @include media-breakpoint('xs') {
                max-width: 100vw;
            }
            max-width: calc(100vw - 250px);
            .mat-menu-content:not(:empty) {
                padding-top: 8px;
                padding-bottom: 8px;
            }
            .mat-menu-content {
                mat-list {
                    mat-list-item {
                        height: un(1.625) !important;
                        .mat-list-item-content {
                            height: un(1.5) !important;
                            .mat-checkbox-layout {
                                .mat-checkbox-label {
                                    font-size: un(.75);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.selector {
            @include media-breakpoint('lt-sm') {
                min-width: un(7);
                max-width: un(20);
            }
            min-width: un(30);
            max-width: un(40);
        }
        &.account {
            @include media-breakpoint('lt-sm') {
                &.small {
                    max-height: 100vh;
                }
            }
            .mat-menu-content {
                padding: un(1) un(1) 0 un(1);
            }
            .mat-menu-item {
                line-height: un(2);
                margin: un(.5) 0;
                padding: 0 un(2);
                text-align: center !important;
            }
        }
        .account {
            &-access {
                background-color: $mat-blue-500;
                color: $white-color;
            }
            &-user {
                height: 100%;
            }
            &-name,
            &-surname {
                color: $mat-grey-800;
                font-weight: 500;
                text-transform: uppercase;
            }
            &-email {
                color: $mat-grey-600;
                display: block;
                font-size: 14px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &-in,
            &-admin {
                background-color: $mat-blue-700;
                color: $white-color;
                font-size: 15px;
                font-weight: 500;
                &:hover,
                &.cdk-program-focused,
                &.cdk-keyboard-focused {
                    &:not([disabled]) {
                        color: $mat-grey-800;
                        background-color: $mat-blue-300;
                    }
                }
            }
            &-out {
                background-color: $mat-grey-100;
                color: $mat-grey-800;
                font-weight: 500;
                &:hover {
                    &:not([disabled]) {
                        background-color: $mat-grey-300;
                    }
                }
            }
        }
        &.mat-menu-filter {
            border: $border-grey;
            max-height: calc(100vh - 250px);
            min-width: un(10);
            padding: un(1);
            max-width: inherit;
            .filter {
                &-column,
                &-check,
                &-date {
                    max-width: un(15.25);
                    font-size: 14px;
                    &.big {
                        max-width: un(30.5);
                    }
                }
                &-label {
                    color: $mat-blue-600;
                    font-weight: 300;
                    padding: un(1) un(1) 0 un(1);
                }
                &-value {
                    margin-top: un(1);
                }
            }
        }
        &.links {
            border: $border-grey;
            min-width: un(10);
            padding: un(1);
            max-width: un(30);
        }
        &.landing {
            max-width: 100vw;
            background: $nav-background;
            width: 100vw;
            .menu {
                cursor: pointer;
                &:hover,
                &:focus {
                    border-bottom: un(.4) solid $mat-green-500;
                }
                &.contact {
                    background-color: $mat-red-500 !important;
                }
                &.active {
                    border-bottom: un(.4) solid $mat-green-500 !important;
                }
                .mat-list-item-content {
                    color: $white-color !important;
                }
            }
        }
        &.button-bar-menu {
            @include media-breakpoint('xs') {
                max-height: 90vh;
                min-width: 80vw !important;
                .mat-select-panel-wrap {
                    flex-basis: auto;
                }
            }
            border: $border-grey;
            max-height: 60vh;
            min-width: 50vw;
            padding: un(1);
            .mat-subheader {
                border-bottom: $border-info;
                color: $mat-blue-700;
                font-weight: 400;
                max-height: un(2);
                padding: 0;
            }
            .mat-pseudo-checkbox {
                display: none;
            }
            .mat-option {
                color: $mat-grey-800;
                height: un(2);
                line-height: un(2);
                padding: 0;
            }
            .mat-option-text {
                .label {
                    margin-left: un(.5);
                }
            }
            .mat-list-text {
                color: $mat-grey-800;
                flex-direction: row;
            }
            .mat-list-item-disabled {
                background-color: $mat-grey-200;
            }
            .mat-list-option,
            .mat-nav-list .mat-list-item,
            .mat-action-list .mat-list-item {
                color: $mat-grey-800;
                line-height: un(2);
                &:hover,
                &:focus {
                    background-color: $mat-blue-400;
                }
            }
            .mat-list-single-selected-option {
                &,
                &:hover,
                &:focus {
                    background: $mat-grey-100;
                }
            }
            [class*=wk-icon] {
                color: $mat-grey-600;
                display: inline-block;
                font-size: un(1.25);
                margin-left: 0;
                margin-right: 0;
                width: un(1.5);
            }
            &.tokens {
                @include media-breakpoint('lt-sm') {
                    max-width: 80vw;
                    max-height: 100vh;
                    min-width: 80vw;
                }
                max-width: 70vw;
                max-height: 60vh;
                min-width: 40vw;
            }
        }
    }
}