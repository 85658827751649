.informa {
    font-size: un(1);
    font-weight: 100;
    &-container {
        @include media-breakpoint('lt-md') {
            margin: 0;
        }
        margin: 0 un(2);
    }
    &-logos {
        @include flex(row, $justify-content: space-around, $align-items: center, $align-content: center, $align-self: center);
        width: 100%;
    }
    &-title {
        color: $mat-blue-700;
        font-size: 1.2em;
    }
    &-section {
        font-size: 1.3em;
        font-weight: 500;
    }
    &-gioc-liquidations {
        color: $mat-green-500 !important;
        font-weight: 100 !important;
        word-break: break-word;
        &.tie {
            white-space: nowrap;
        }
    }
    &-gioc {
        color: $mat-blue-700;
        font-weight: 100 !important;
        word-break: break-word;
    }
    &-text {
        color: $mat-grey-800;
        font-size: un(1);
        font-weight: 100;
    }
    &-message {
        @include media-breakpoint('lt-md') {
            margin: 0;
        }
        margin: un(1) un(3);
        text-align: justify;
    }
    &-list {
        list-style: none;
        .check::before {
            content: '\2713';
            color: $mat-blue-700;
            display: inline-block;
            font-weight: 600;
            font-size: un(1.5);
            margin-left: -1.5em;
            margin-right: .5em;
            margin-top: .5em;
        }
    }
    &-important {
        font-weight: 400;
    }
    &-contact {
        background-color: $mat-red-500;
        color: $white-color;
        display: block;
        font-size: un(1);
        font-weight: 100;
        margin: un(1) 0;
    }
    &-foot {
        @include flex(column, $justify-content: center, $align-content: center, $align-items: flex-end);
        line-height: un(2);
        margin: un(1);
        .logo-ll {
            margin-left: un(.25);
        }
    }
    &-alert {
        @include flex(column, $justify-content: center, $align-content: center, $align-items: flex-start);
        margin: un(1) auto;
        min-width: un(20);
    }
    &-button {
        margin: un(1) auto;
    }
    &-link {
        cursor: pointer;
        display: block;
        margin: un(.5) auto;
    }
    &-tips {
        width: 100%;
    }
    &-video {
        @include flex(row, $justify-content: stretch, $align-content: stretch, $align-items: stretch);
        @include media-breakpoint('lt-md') {
            flex-wrap: wrap;
        }
        gap: un(.5);
        &-src {
            @include media-breakpoint('lt-md') {
                flex: 1 1 100%;
            }
            @include media-breakpoint('md') {
                align-self: baseline;
                flex: 1 1 50%;
            }
            align-self: center;
            flex: 1 1 60%;
        }
        &-info {
            @include media-breakpoint('gt-lg') {
                flex: 1 1 30%;
                margin: 0 un(4);
            }
            @include size(100%, inherit);
            @include flex(column, $justify-content: center, $align-content: stretch, $align-items: stretch, $align-self: stretch);
            background-color: $mat-grey-50;
            border: $border-grey;
            flex: 1 1 40%;
        }
        &-text {
            @include flex(column, $justify-content: center, $align-content: center, $align-items: center);
            max-width: un(25);
            padding: un(2);
        }
    }
    .video-responsive {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
    }
    .video-responsive iframe,
    .video-responsive object,
    .video-responsive embed {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .video-logo-liquidations {
        cursor: pointer;
        display: block;
        padding: 25%;
        border-radius: 0;
        background-color: $white-color;
        background-image: url($logo-auctions);
        background-attachment: inherit;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    strong {
        font-weight: 500;
    }
}

.notification,
.notification *,
.notification *::before,
.notification *::after {
    box-sizing: border-box;
}

.news {
    @include flex(row, $justify-content: center, $align-items: center, $align-content: center);
    @include media-breakpoint('lt-sm') {
        flex-wrap: wrap;
    }
    &.column {
        @include flex(column, $justify-content: center, $align-items: flex-start, $align-content: center);
        line-height: un(1.5);
    }
    &.catastro {
        .container {
            padding-left: un(1);
            padding-right: un(1);
            padding-bottom: un(1);
        }
    }
    .img {
        flex: 0 0 auto;
        margin: 0 0 0 un(1);
        &.column {
            margin: un(1);
        }
    }
    .logo {
        @include size(un(8));
        &.catastro {
            @include size(100%);
        }
    }
    .container {
        flex: 1 1 auto;
        line-height: un(1.5);
        margin: 0 un(1);
        width: calc(100% - 32px);
    }
    .content,
    .content .link,
    .content p {
        .link {
            cursor: pointer;
        }
    }
    .link {
        cursor: pointer;
    }
}

.info {
    @include flex(column, $justify-content: center, $align-items: stretch, $align-content: flex-start, $align-self: flex-start);
    &.u28 {
        max-width: un(28);
    }
    &-header {
        color: $mat-blue-700;
        text-align: center;
        .mat-icon {
            &.big {
                @include size(un(3));
                font-size: un(3);
            }
        }
    }
    &-title {
        font-size: 1.5em;
    }
    &-subtitle {
        color: $mat-blue-700;
        font-size: 1.1em;
        margin-top: un(1);
        text-align: center;
    }
    &-message {
        font-size: un(1);
        font-weight: 100;
        margin: un(1);
    }
    &-gioc {
        color: $mat-blue-700;
        font-size: 1.2em;
        font-weight: 100 !important;
    }
    &-important {
        font-weight: 400;
    }
    &-check {
        list-style: none;
        .check::before {
            content: '\2713';
            color: $mat-blue-700;
            display: inline-block;
            font-weight: 600;
            font-size: un(1.5);
            margin-left: -1.5em;
            margin-right: .5em;
            margin-top: .5em;
        }
    }
    &-footer {
        @include media-breakpoint('lt-md') {
            align-self: center;
            margin-right: 0;
            padding-top: 0;
        }
        align-self: flex-end;
        margin-right: 10em;
        padding-top: un(3);
    }
    .row {
        @include flex(row, $justify-content: center, $align-items: center, $align-content: center, $align-self: center);
        gap: un(1);
    }
    .font-weight-500 {
        font-weight: 500;
    }
}