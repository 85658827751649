@mixin mat-form-field-outline($theme) {
    $mat-form-field-outline-oval-border-radius: 50px;
    $mat-form-field-outline-border-radius: 2.5px;
    $mat-form-field-outline-width: .1px;
    $mat-form-field-outline-thick-width: .5px;
    $mat-form-field-prefix-read-only-color: inherit;
    $mat-form-field-input-placeholder: inherit;
    .mat-form-field-appearance-outline {
        .mat-form-field-prefix,
        .mat-form-field-suffix {
            bottom: 0;
        }
        &.kendo {
            .k-editor-content {
                min-height: un(10);
            }
            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    transform: translateY(-1em) scale(0.75);
                    width: 120%;
                    mat-label {
                        background-color: $white-color;
                        padding: 0 un(.5) 0 un(.125);
                    }
                }
            }
        }
        &.filter-list {
            .mat-form-field-wrapper {
                margin: 0;
            }
        }
        .mat-form-field-subscript-wrapper {
            margin-top: 2px;
            font-size: 11px;
            padding: 0;
            position: relative;
            .mat-error {
                display: inline-block;
            }
            .info {
                display: inline-block;
                color: $color-info;
            }
        }
        .mat-form-field-label {
            margin-top: 0;
            mat-label {
                padding-left: 2px;
            }
            &.mat-form-field-empty {
                margin-top: -0.0625em;
                top: 1.2em;
            }
        }
        &.mat-focused {
            .mat-form-field-label {
                &.mat-form-field-empty {
                    margin-top: .625em;
                }
            }
        }
        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
            border: $mat-form-field-outline-width solid currentColor;
            min-width: $mat-form-field-outline-border-radius;
        }
        .mat-form-field-outline-start {
            border-radius: $mat-form-field-outline-border-radius 0 0 $mat-form-field-outline-border-radius;
            border-right-style: none;
            [dir='rtl'] & {
                border-right-style: solid;
                border-left-style: none;
                border-radius: 0 $mat-form-field-outline-border-radius $mat-form-field-outline-border-radius 0;
            }
        }
        .mat-form-field-outline-end {
            border-radius: 0 $mat-form-field-outline-border-radius $mat-form-field-outline-border-radius 0;
            border-left-style: none;
            flex-grow: 1;
            [dir='rtl'] & {
                border-left-style: solid;
                border-right-style: none;
                border-radius: $mat-form-field-outline-border-radius 0 0 $mat-form-field-outline-border-radius;
            }
        }
        &.shape-oval {
            @include media-breakpoint('lt-sm') {
                max-width: 100%;
            }
            min-width: un(17);
            width: 100%;
            &.mat-form-field-disabled {
                .mat-form-field-outline {
                    background-color: transparent;
                }
                .mat-form-field-suffix {
                    color: $mat-grey-200;
                }
            }
            .mat-form-field-subscript-wrapper,
            .mat-form-field-wrapper {
                margin: 0
            }
            .mat-form-field-outline-start,
            .mat-form-field-outline-end {
                min-width: $mat-form-field-outline-oval-border-radius;
            }
            .mat-form-field-outline-start {
                border-radius: $mat-form-field-outline-oval-border-radius 0 0 $mat-form-field-outline-oval-border-radius;
                [dir='rtl'] & {
                    border-radius: 0 $mat-form-field-outline-oval-border-radius $mat-form-field-outline-oval-border-radius 0;
                }
            }
            .mat-form-field-outline-end {
                border-radius: 0 $mat-form-field-outline-oval-border-radius $mat-form-field-outline-oval-border-radius 0;
                [dir='rtl'] & {
                    border-radius: $mat-form-field-outline-oval-border-radius 0 0 $mat-form-field-outline-oval-border-radius;
                }
            }
            .mat-form-field-suffix {
                top: un(.375);
                .mat-icon-button {
                    max-width: un(1.5);
                    max-height: un(1.5);
                }
            }
            .mat-form-field-infix {
                padding: 4px 0 8px 0;
                border-top: 11px solid transparent;
            }
            .mat-form-field-label {
                padding-left: 54px;
            }
        }
        .mat-form-field-outline-gap {
            border-radius: 0.000001px;
            border: $mat-form-field-outline-width solid currentColor;
            border-left-style: none;
            border-right-style: none;
        }
        &.catastral {
            .mat-form-field-outline-gap,
            .mat-form-field-outline-start,
            .mat-form-field-outline-end {
                border-color: $mat-blue-500;
            }
        }
        &.mat-form-field-can-float.mat-form-field-should-float {
            .mat-form-field-outline-gap {
                border-top-color: transparent;
            }
        }
        .mat-form-field-outline-thick {
            opacity: 0;
            color: $mat-blue-500;
            .mat-form-field-outline-start,
            .mat-form-field-outline-end,
            .mat-form-field-outline-gap {
                border-width: $mat-form-field-outline-thick-width;
            }
        }
        &.mat-focused,
        &.mat-form-field-invalid {
            .mat-form-field-outline {
                opacity: 0;
                transition: opacity 100ms $swift-ease-out-timing-function;
            }
            .mat-form-field-outline-thick {
                opacity: 1;
            }
        }
        &:not(.mat-form-field-disabled) .mat-form-field-flex:hover {
            & .mat-form-field-outline {
                opacity: 0;
                transition: opacity 600ms $swift-ease-out-timing-function;
            }
            & .mat-form-field-outline-thick {
                opacity: 1;
            }
        }
        .mat-form-field-outline {
            color: $mat-grey-400;
        }
        .mat-form-field-infix {
            border-top: .55em solid transparent;
            padding: .3em 0;
            .mat-form-field-label {
                @extend %mat-form-field-required-marker;
            }
        }
        .mat-datepicker-toggle {
            .mat-icon-button {
                top: .25em;
            }
        }
        &.read-only {
            .mat-form-field-outline-start,
            .mat-form-field-outline-end,
            .mat-form-field-outline-gap {
                border: none !important;
            }
            .mat-form-field-flex {
                padding: 0 4px;
            }
            .mat-form-field-infix {
                padding: 4px 0;
                border-top: none;
                color: $mat-form-field-prefix-read-only-color;
            }
            .mat-form-field-wrapper {
                background-color: $mat-form-field-label-read-only-bg-color;
            }
            .mat-input-element {
                @include input-placeholder {
                    color: $mat-form-field-input-placeholder;
                }
            }
        }
        &.settings {
            max-width: un(5);
            .mat-form-field-outline {
                background-color: $white-color;
            }
            .mat-form-field-wrapper {
                margin: 0;
            }
            .mat-form-field-label {
                background-color: $white-color;
                &.mat-empty.mat-form-field-empty {
                    color: transparent;
                }
            }
        }
        &.date {
            line-height: un(1.5);
            .mat-form-field-infix {
                padding: 0;
                border-top: none;
            }
        }
    }
    .mat-select-panel-filter {
        padding: 16px;
        .mat-form-field-suffix {
            top: 0;
        }
        .mat-datepicker-toggle {
            .mat-icon-button {
                top: .25em;
            }
        }
        &.read-only {
            .mat-form-field-outline-start,
            .mat-form-field-outline-end,
            .mat-form-field-outline-gap {
                border: none !important;
            }
            .mat-form-field-infix {
                padding: 4px 0;
                border-top: none;
                color: $mat-form-field-prefix-read-only-color;
            }
            .mat-form-field-wrapper {
                background-color: $white-color;
            }
            .mat-input-element {
                @include input-placeholder {
                    color: $mat-form-field-input-placeholder;
                }
            }
        }
    }
    .panel-content {
        .mat-paginator {
            @include media-breakpoint('sm') {
                margin-right: un(2);
            }
            @include media-breakpoint('xs') {
                margin-bottom: un(2);
            }
            margin-bottom: un(1);
        }
    }
    .mat-paginator {
        margin-bottom: 0;
        .mat-paginator-container {
            min-height: un(2.5);
        }
        .mat-paginator-page-size {
            flex: 1;
            text-align: right;
        }
        .mat-paginator-page-size-label {
            flex: 1;
            font-size: 1.2em;
            color: $mat-blue-400;
        }
        .mat-paginator-page-size-select {
            min-width: un(5);
            .mat-form-field-outline {
                color: $mat-blue-400;
            }
        }
        .mat-paginator-range-label {
            margin: 0;
        }
    }
}